import { IBlockImage } from 'types/ContentfulTypes';
import { ImageSizes, LayoutType } from 'types/Image';
import { ObjectFit, ObjectPosition } from '../../../../constants/styling';
import Image from '../../../Image/Image';
import ContentfulPageLink from '../ContentfulPageLink/ContentfulPageLink';
import { ContentfulImageBlockWrapper, ContentfulImageBlockWrapperProps } from './ContentfulImageBlock.styled';

interface ContentfulImageBlockProps extends ContentfulImageBlockWrapperProps {
  className?: string;
  content: IBlockImage;
  layout?: LayoutType;
  objectFit?: ObjectFit;
  objectPosition?: ObjectPosition;
  priority?: boolean;
  quality?: number;
  sizes?: ImageSizes;
}

const ContentfulImageBlock = ({
  className,
  content,
  hasRelativeParent,
  height,
  layout = 'fill',
  objectFit = 'cover',
  objectPosition = 'center center',
  quality = 100,
  roundBorders = 'all',
  sizes = { default: 200 },
  ...imageProps
}: ContentfulImageBlockProps) => {
  const { focalPoint, image, link } = content.fields;

  if (!image?.fields) {
    return null;
  }

  const { description, file, title } = image.fields;

  const getObjectPosition = () => {
    const imageDimensions = file?.details?.image;
    const focalPointValues = focalPoint?.focalPoint;

    if (focalPoint?.focalPoint && imageDimensions) {
      return `${Math.round((focalPointValues?.x / imageDimensions.width) * 100)}% ${Math.round(
        (focalPointValues?.y / imageDimensions.height) * 100
      )}%`;
    }

    return objectPosition;
  };

  const body = file?.url && (
    <Image
      alt={title || description}
      layout={layout}
      objectFit={objectFit}
      objectPosition={getObjectPosition()}
      quality={quality}
      sizes={sizes || undefined}
      src={`https:${file?.url}`}
      {...imageProps}
    />
  );

  return (
    <ContentfulImageBlockWrapper
      className={className}
      hasRelativeParent={hasRelativeParent}
      height={height}
      roundBorders={roundBorders}
    >
      {link ? <ContentfulPageLink link={link}>{body}</ContentfulPageLink> : body}
    </ContentfulImageBlockWrapper>
  );
};

export default ContentfulImageBlock;
