import { useState } from 'react';
import type { Image as ImageType } from 'types/Image';
import { Image } from 'ui/Image/Image';
import { OptionalLink } from 'ui/Link/Link';
import { Text } from 'ui/Text/Text';

type CategoryBlockProps = {
  backgroundColor?: string;
  backgroundColorHover?: string;
  borderColor?: string;
  borderColorHover?: string;
  image?: ImageType;
  link: string;
  textColor?: string;
  textColorHover?: string;
  title?: string;
};

const CategoryBlock = ({
  backgroundColor,
  backgroundColorHover,
  borderColor,
  borderColorHover,
  image,
  link,
  textColor,
  textColorHover,
  title,
}: CategoryBlockProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <OptionalLink
      href={link}
      className="relative flex flex-col overflow-hidden rounded-xl border border-transparent bg-accent-40 transition-colors hover:border-accent-60"
      style={{
        backgroundColor: isHovered ? backgroundColorHover : backgroundColor,
        borderColor: isHovered ? borderColorHover : borderColor,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {image?.src && (
        <div className="relative aspect-[10/7] w-full">
          <Image
            className="object-contain object-top"
            src={image.src}
            alt={image.alt ?? ''}
            fill
            useNext
            nextSizes={{ default: 300 }}
          />
        </div>
      )}
      {title && (
        <Text
          tag="h3"
          className="w-full pb-4 text-center align-bottom transition-colors"
          style={{ color: isHovered ? textColorHover : textColor }}
        >
          {title}
        </Text>
      )}
    </OptionalLink>
  );
};

export default CategoryBlock;
