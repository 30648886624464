import Flex from 'components/Flex/Flex';
import { BREAKPOINTS } from 'constants/breakpoints';
import { ICON_NAMES } from 'constants/icon';
import { BORDER_RADIUS_VALUES } from 'constants/styling';
import { produce } from 'immer';
import { IGroupingClickableCardsFields, ITileContent } from 'types/ContentfulTypes';
import { DEFAULT_SIZE_KEY, ImageSizes } from 'types/Image';
import ContentfulImageBlock from '../ContentfulImageBlock/ContentfulImageBlock';
import { TileLifeStyleVariant } from '../types';
import {
  ContentWrapper,
  StyledCallToAction,
  StyledContentfulPageLink,
  StyledDescription,
  StyledMyAccountStyleIconMobile,
  StyledRegularIcon,
  StyledTileWrapper,
  StyledTitle,
} from './ContentfulTileLifeStyle.styled';

interface ContentfulTileLifeStyleProps {
  className?: string;
  content: ITileContent;
  showLinkLabel?: boolean;
  sizes?: ImageSizes;
  stylingType: IGroupingClickableCardsFields['stylingType'];
  tileLifeStyleVariant?: TileLifeStyleVariant;
}

const ContentfulTileLifeStyle = ({
  className,
  content,
  showLinkLabel,
  sizes = {
    [BREAKPOINTS.XL]: 580,
    [BREAKPOINTS.LG]: 680,
    [BREAKPOINTS.MD]: 475,
    [BREAKPOINTS.SM]: 743,
    [DEFAULT_SIZE_KEY]: 400,
  },
  stylingType,
}: ContentfulTileLifeStyleProps) => {
  if (!content?.fields) {
    return null;
  }

  const { description, image, link: cta, title } = content.fields;

  const ctaLink = cta?.fields?.link;
  const ctaLabel = cta?.fields?.label;

  // Exclude the image's link as this would cause a link inside of a link error
  const slimmedImage = image
    ? produce(image, (draft) => {
        draft.fields.link = undefined;
      })
    : undefined;

  // Exclude the cta's link as this would cause a link inside of a link error
  const slimmedCta = cta
    ? produce(cta, (draft) => {
        draft.fields.link = undefined;
      })
    : undefined;

  return (
    <StyledTileWrapper
      className={`contentful-tile-lifestyle ${className || ''}`}
      withAnimation={ctaLink}
      stylingType={stylingType}
    >
      <StyledContentfulPageLink link={ctaLink}>
        {slimmedImage && (
          <ContentfulImageBlock
            content={slimmedImage}
            height={200}
            roundBorders={[BORDER_RADIUS_VALUES.TOP_LEFT, BORDER_RADIUS_VALUES.TOP_RIGHT]}
            sizes={sizes}
          />
        )}
        <ContentWrapper stylingType={stylingType}>
          <Flex flexDirection="column" gap={125}>
            <StyledTitle stylingType={stylingType} fontSize={100} marginBottom={0} tag="h4" type="h3">
              {title}
            </StyledTitle>
            <StyledDescription stylingType={stylingType} fontSize={88}>
              {description}
            </StyledDescription>
          </Flex>

          {ctaLabel && ctaLink && (
            <>
              {showLinkLabel ? (
                <>
                  <StyledCallToAction content={slimmedCta} stylingType={stylingType} />
                  <StyledMyAccountStyleIconMobile
                    stylingType={stylingType}
                    name={ICON_NAMES.ANGLE_RIGHT}
                    type="fa"
                    iconStyling="fas"
                    color="black"
                  />
                </>
              ) : (
                <StyledRegularIcon type="fa" color="" name={ICON_NAMES.ANGLE_RIGHT} size={100} />
              )}
            </>
          )}
        </ContentWrapper>
      </StyledContentfulPageLink>
    </StyledTileWrapper>
  );
};

export default ContentfulTileLifeStyle;
