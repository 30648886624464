import { IconStyle, Spacer } from 'constants/styling';
import { Color } from 'types/Color';
import { IIcon } from 'types/ContentfulTypes';
import { ICON_STYLES, ICON_STYLE_DEFAULT } from '../../../../constants/icon';
import { GenericIcon } from '../../../Icon/Icon';
import ContentfulPageLink from '../ContentfulPageLink/ContentfulPageLink';

interface ContentfulIconProps {
  className?: string;
  color?: Color;
  content: IIcon;
  preventLink?: boolean;
  size?: Spacer;
  styling?: IconStyle;
}

const ContentfulIcon = ({
  className,
  color = 'black',
  content,
  preventLink,
  size = 100,
  styling = ICON_STYLE_DEFAULT,
}: ContentfulIconProps) => {
  if (!content?.fields) {
    return null;
  }

  const { icon, link, type } = content.fields;

  if (!icon) {
    return null;
  }

  const iconStylingMap: Record<typeof type, IconStyle> = {
    Brand: ICON_STYLES.BRAND,
    Custom: styling,
    Regular: styling,
  };

  const body = (
    <GenericIcon
      className={className}
      color={color}
      type={type === 'Custom' ? 'custom' : 'fa'}
      iconStyling={iconStylingMap[type] || styling}
      name={icon}
      size={size}
    />
  );

  return link && !preventLink ? (
    <ContentfulPageLink className={`contentful-icon-link ${className}`} link={link}>
      {body}
    </ContentfulPageLink>
  ) : (
    body
  );
};

export default ContentfulIcon;
