import { Text, TextProps } from 'ui/Text/Text';
import { cn } from 'utils/cn';

const ContentfulGroupingHeading = ({ children, className, tag = 'h3', type = 'h2', ...props }: TextProps) => {
  if (!children) {
    return null;
  }

  return (
    <Text className={cn('mb-0 font-bold', className)} tag={tag} type={type} {...props}>
      {children}
    </Text>
  );
};

export default ContentfulGroupingHeading;
