import type { Image as ImageType } from 'types/Image';
import { Image } from 'ui/Image/Image';
import { OptionalLink } from 'ui/Link/Link';
import { Text } from 'ui/Text/Text';

type CategoryA1CardProps = {
  description?: string;
  image: ImageType;
  link: string;
  title?: string;
};

const CategoryA1Card = ({ description, image, link, title }: CategoryA1CardProps) => {
  if (!image.src && !title && !description) return null;

  return (
    <div className="flex flex-col gap-4">
      <div className=" h-[150px] w-full rounded-xl bg-accent-20 py-3">
        <OptionalLink href={link} className="relative block h-full w-full">
          {image?.src && (
            <Image
              className="object-contain"
              src={image.src}
              alt={image.alt ?? ''}
              fill
              useNext
              nextSizes={{ default: 120 }}
            />
          )}
        </OptionalLink>
      </div>
      {title && <Text type="h3">{title}</Text>}
      {description && <Text html={description} />}
    </div>
  );
};
export default CategoryA1Card;
