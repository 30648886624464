import styled, { css } from 'styled-components';
import { spacer, color, variable, breakpointUp } from 'theme';
import Icon from 'components/Icon/Icon';
import ContentfulPageLink from '../ContentfulPageLink/ContentfulPageLink';
import ContentfulCallToAction from '../ContentfulCallToAction/ContentfulCallToAction';
import { IGroupingClickableCardsFields } from 'types/ContentfulTypes';
import Typography from 'components/Typography/Typography';
import { BREAKPOINTS } from 'constants/breakpoints';

interface StyledTileWrapperProps extends VariantProps {
  withAnimation?: boolean;
}

interface VariantProps {
  stylingType: IGroupingClickableCardsFields['stylingType'];
}

export const StyledRegularIcon = styled(Icon)`
  position: absolute;
  right: ${spacer(50)};
  bottom: ${spacer(50)};
  fill: ${color('secondary-300')};
  color: ${color('secondary-300')};
  transition: ${variable('transition')};
`;

export const StyledTileWrapper = styled.div<StyledTileWrapperProps>`
  height: 100%;

  img {
    transition: ${variable('transition')};
  }

  &:hover {
    img {
      transform: ${({ withAnimation }) => withAnimation && 'scale3d(1.01, 1.01, 1.01);'};
    }

    ${StyledRegularIcon} {
      fill: ${color('secondary-400')};
      color: ${color('secondary-400')};
    }
  }

  ${({ stylingType }) => {
    switch (stylingType) {
      case 'My Account':
        return css`
          background-color: ${color('whisper-100')};
          box-shadow: none;
          border-radius: ${spacer(25)};
          ${variable('border')}

          &:hover {
            background-color: ${color('primary-transparent-100')};
            border-color: ${color('primary-300')};
          }

          &:focus {
            background-color: ${color('primary-100')};
          }

          ${breakpointUp(
            BREAKPOINTS.MD,
            css`
              border-radius: ${spacer(75)};
            `
          )}
        `;
      default:
        return css`
          background-color: ${color('white')};
          box-shadow: ${variable('box-shadow')};
        `;
    }
  }}
`;

export const StyledContentfulPageLink = styled(ContentfulPageLink)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ContentWrapper = styled.div<VariantProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0 0 ${variable('border-radius')} ${variable('border-radius')};
  gap: ${spacer(150)};
  flex-grow: 1;
  position: relative;

  ${({ stylingType }) => {
    switch (stylingType) {
      case 'My Account':
        return css`
          flex-direction: row;
          padding: ${spacer(75)} ${spacer(150)};
          align-items: center;

          ${breakpointUp(
            BREAKPOINTS.MD,
            css`
              flex-direction: column;
              text-align: left;
              align-items: flex-start;
              padding: ${spacer(150)} ${spacer(225)};
            `
          )}
        `;
      default:
        return css`
          text-align: center;
          align-items: center;
          padding: ${spacer(150)};
        `;
    }
  }}
`;

export const StyledCallToAction = styled(ContentfulCallToAction)<VariantProps>`
  ${({ stylingType }) => {
    switch (stylingType) {
      case 'My Account':
        return css`
          && {
            color: ${color('secondary-300')};
            font-weight: bold;
          }

          display: none;
          ${breakpointUp(
            BREAKPOINTS.MD,
            css`
              display: unset;
            `
          )}
        `;
    }
  }}
`;

export const StyledTitle = styled(Typography)<VariantProps>`
  ${({ stylingType }) => {
    switch (stylingType) {
      case 'My Account':
        return css`
          font-weight: normal;
          ${breakpointUp(
            BREAKPOINTS.MD,
            css`
              font-weight: bold;
            `
          )}
        `;
    }
  }}
`;

export const StyledDescription = styled(Typography)<VariantProps>`
  ${({ stylingType }) => {
    switch (stylingType) {
      case 'My Account':
        return css`
          display: none;
          ${breakpointUp(
            BREAKPOINTS.MD,
            css`
              display: unset;
            `
          )}
        `;
    }
  }}
`;

export const StyledMyAccountStyleIconMobile = styled(Icon)<VariantProps>`
  ${({ stylingType }) => {
    switch (stylingType) {
      case 'My Account':
        return css`
          ${breakpointUp(
            BREAKPOINTS.MD,
            css`
              display: none;
            `
          )}
        `;
    }
  }}
`;
