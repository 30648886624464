import { ReactNode } from 'react';
import { cn } from 'utils/cn';

interface ContentfulGroupingWrapperProps {
  children?: ReactNode;
  className?: string;
}

const ContentfulGroupingWrapper = ({ children, className }: ContentfulGroupingWrapperProps) => {
  if (!children) {
    return null;
  }

  return <div className={cn('flex flex-col gap-4', className)}>{children}</div>;
};

export default ContentfulGroupingWrapper;
