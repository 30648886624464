import { BORDER_RADIUS_VALUES, BorderRadiusOption } from 'constants/styling';
import styled, { css } from 'styled-components';
import { variable } from 'theme';
import { handleStylingValue } from 'utils/textUtil';

export interface ContentfulImageBlockWrapperProps {
  hasRelativeParent?: boolean;
  height?: string | number;
  roundBorders?: boolean | 'all' | BorderRadiusOption[];
}

export const ContentfulImageBlockWrapper = styled.div<ContentfulImageBlockWrapperProps>`
  height: ${({ height }) => height && handleStylingValue(height)};

  position: ${({ hasRelativeParent }) => !hasRelativeParent && 'relative'};

  ${({ roundBorders }) => {
    if (roundBorders === 'all') {
      return css`
        img {
          border-radius: ${variable('border-radius')};
        }
      `;
    }

    if (Array.isArray(roundBorders) && roundBorders?.length > 0) {
      return css`
        img {
          border-radius: ${roundBorders.includes(BORDER_RADIUS_VALUES.TOP_LEFT) ? variable('border-radius') : 0}
            ${roundBorders.includes(BORDER_RADIUS_VALUES.TOP_RIGHT) ? variable('border-radius') : 0}
            ${roundBorders.includes(BORDER_RADIUS_VALUES.BOTTOM_RIGHT) ? variable('border-radius') : 0}
            ${roundBorders.includes(BORDER_RADIUS_VALUES.BOTTOM_LEFT) ? variable('border-radius') : 0};
        }
      `;
    }

    return null;
  }};
`;
