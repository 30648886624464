import CategoryA1Card from 'components/CategoryA1Card/CategoryA1Card';
import CategoryBlock from 'components/CategoryBlock/CategoryBlock';
import SliderEmbla from 'components/Slider/SliderEmbla/SliderEmbla';
import { SlidesObject } from 'components/Slider/SliderEmbla/SliderEmbla.styled';
import { BREAKPOINTS } from 'constants/breakpoints';
import { ARROW_POSITIONS } from 'constants/generic';
import { EmblaOptionsType } from 'embla-carousel';
import useRouter from 'hooks/useRouter';
import breakpointValues from 'theme/variables/breakpoints';
import { IGroupingClickableCards } from 'types/ContentfulTypes';
import { getContentfulPageLink } from 'utils/contentfulUtil';
import ContentfulGroupingHeading from '../ContentfulGroupingHeading/ContentfulGroupingHeading';
import ContentfulGroupingWrapper from '../ContentfulGroupingWrapper/ContentfulGroupingWrapper';
import ContentfulTileLifeStyle from '../ContentfulTileLifeStyle/ContentfulTileLifeStyle';
import { TileLifeStyleVariant } from '../types';

const variantColors = ['primary-400', 'secondary-300', 'primary-400', 'black'];

type ContentfulGroupingClickableCardsProps = {
  content: IGroupingClickableCards;
  showLinkLabel?: boolean;
  tileLifeStyleVariant?: TileLifeStyleVariant;
};

const ContentfulGroupingClickableCards = ({
  content,
  showLinkLabel,
  tileLifeStyleVariant,
}: ContentfulGroupingClickableCardsProps) => {
  const { locale } = useRouter();
  if (!content?.fields) {
    return null;
  }

  const { items, title } = content.fields;

  const options: EmblaOptionsType = {
    breakpoints: {
      [`(min-width: ${breakpointValues.md}px)`]: { slidesToScroll: 3 },
      [`(min-width: ${breakpointValues.lg}px)`]: { slidesToScroll: 4 },
      [`(min-width: ${breakpointValues.xl}px)`]: { slidesToScroll: 5 },
    },
  };

  const showDots = { [BREAKPOINTS.XS]: false };
  const showArrows = { [BREAKPOINTS.MD]: true };
  const slidesToShow: SlidesObject = {
    [BREAKPOINTS.XS]: 1.5,
    [BREAKPOINTS.MD]: 3,
    [BREAKPOINTS.LG]: 4,
    [BREAKPOINTS.XL]: 5,
  };

  return (
    <ContentfulGroupingWrapper>
      {title && <ContentfulGroupingHeading>{title}</ContentfulGroupingHeading>}

      {content.fields?.stylingType === 'Default' && (
        <SliderEmbla
          showDots={showDots}
          showArrows={showArrows}
          options={options}
          arrowPosition={ARROW_POSITIONS.CENTER}
          slidesToShow={slidesToShow}
        >
          {items.map((item, index) => (
            <div key={item.sys.id} className="h-full p-1">
              <ContentfulTileLifeStyle
                // @ts-ignore
                background={variantColors[(index + 4) % 4]}
                content={item}
                showLinkLabel={showLinkLabel}
                tileLifeStyleVariant={tileLifeStyleVariant}
                stylingType={content.fields?.stylingType}
              />
            </div>
          ))}
        </SliderEmbla>
      )}

      {content.fields?.stylingType === 'My Account' && (
        <>
          <div className="flex flex-col gap-2 md:hidden">
            {items.map((item, index) => (
              <ContentfulTileLifeStyle
                key={index}
                // @ts-ignore
                background={variantColors[(index + 4) % 4]}
                content={item}
                showLinkLabel={showLinkLabel}
                stylingType={content.fields?.stylingType}
              />
            ))}
          </div>
          <SliderEmbla
            className="hidden md:block"
            showDots={showDots}
            showArrows={showArrows}
            options={options}
            arrowPosition={ARROW_POSITIONS.CENTER}
            slidesToShow={slidesToShow}
          >
            {items.map((item, index) => (
              <div key={item.sys.id} className="h-full p-1">
                <ContentfulTileLifeStyle
                  // @ts-ignore
                  background={variantColors[(index + 4) % 4]}
                  content={item}
                  showLinkLabel={showLinkLabel}
                  tileLifeStyleVariant={tileLifeStyleVariant}
                  stylingType={content.fields?.stylingType}
                />
              </div>
            ))}
          </SliderEmbla>
        </>
      )}

      {content.fields.stylingType === 'Category Blocks' && (
        <div className="md:grid-cols grid grid-cols-2 gap-4 md:[grid-template-columns:repeat(auto-fit,minmax(150px,1fr))]">
          {items
            .slice(0, 6)
            .map(
              (
                {
                  fields: {
                    backgroundColor,
                    backgroundColorHover,
                    borderColor,
                    borderColorHover,
                    image,
                    link,
                    textColor,
                    textColorHover,
                    title,
                  },
                },
                index,
              ) => (
                <CategoryBlock
                  key={index}
                  title={title}
                  image={{
                    alt: image?.fields.image.fields.title,
                    src: image?.fields.image.fields.file?.url,
                  }}
                  link={link && getContentfulPageLink(link, locale)}
                  backgroundColor={backgroundColor}
                  backgroundColorHover={backgroundColorHover}
                  borderColor={borderColor}
                  borderColorHover={borderColorHover}
                  textColor={textColor}
                  textColorHover={textColorHover}
                />
              ),
            )}
        </div>
      )}
      {content.fields.stylingType === 'A1 Cards' && (
        <div className="md:grid-cols grid grid-cols-2 gap-4 md:[grid-template-columns:repeat(auto-fit,minmax(260px,1fr))]">
          {items.map(({ fields: { description, image, link, title } }, index) => (
            <CategoryA1Card
              key={index}
              title={title}
              image={{
                alt: image?.fields.image.fields.title,
                src: image?.fields.image.fields.file?.url,
              }}
              link={link && getContentfulPageLink(link, locale)}
              description={description}
            />
          ))}
        </div>
      )}
    </ContentfulGroupingWrapper>
  );
};

export default ContentfulGroupingClickableCards;
