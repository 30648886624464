import { ButtonProps } from 'components/Button/Button';
import { IconProps } from 'components/Icon/Icon';
import { ReactNode } from 'react';
import { ICallToAction } from 'types/ContentfulTypes';
import ContentfulIcon from '../ContentfulIcon/ContentfulIcon';
import ContentfulPageLink from '../ContentfulPageLink/ContentfulPageLink';

interface ContentfulCallToActionProps {
  buttonProps?: ButtonProps;
  children?: ReactNode;
  className?: string;
  content?: ICallToAction;
  hideIfInvalid?: boolean;
  iconProps?: IconProps;
  type?: string;
}

const ContentfulCallToAction = ({
  buttonProps,
  children,
  className,
  content,
  hideIfInvalid,
  iconProps,
  type,
}: ContentfulCallToActionProps) => {
  if (!content?.fields) {
    return null;
  }

  const { icon, iconPosition, label, link, type: cfType } = content.fields;
  const styling = `contentful-call-to-action ${className || ''}`;
  const mappedIconPosition = iconPosition ? (iconPosition.toLowerCase() as Lowercase<typeof iconPosition>) : undefined;

  return (
    <ContentfulPageLink
      buttonProps={buttonProps}
      className={styling}
      hideIfInvalid={hideIfInvalid}
      iconComponent={icon && <ContentfulIcon color="" content={icon} preventLink />}
      iconPosition={mappedIconPosition}
      iconProps={iconProps}
      label={children || label}
      link={link}
      type={type || cfType}
    />
  );
};

export default ContentfulCallToAction;
