import type { IconPrefix } from '@fortawesome/fontawesome-svg-core';
import type { Property } from 'csstype';

export const SPACERS = [
  0,
  25,
  50,
  75,
  100,
  125,
  150,
  175,
  200,
  225,
  250,
  300,
  400,
  500,
  'small',
  'medium',
  'large',
  'auto',
] as const;
export type Spacer = (typeof SPACERS)[number];

export type Margin = Spacer | 'auto';

export const FONT_SIZES = [75, 88, 100, 125, 150, 175, 200, 250, 300] as const;
export type FontSize = (typeof FONT_SIZES)[number];

export const FONT_WEIGHTS = [400, 500, 700, 'normal', 'medium', 'bold', 'italic'] as const;
export type FontWeight = (typeof FONT_WEIGHTS)[number];

export const ICON_STYLES = {
  FAB: 'fab',
  FAD: 'fad',
  FAL: 'fal',
  FAR: 'far',
  FAS: 'fas',
} as const;
export const ICON_STYLES_VALUES = Object.values(ICON_STYLES);
export type IconStyle = Extract<IconPrefix, 'fab' | 'fad' | 'fal' | 'far' | 'fas'>;

export const ICON_POSITIONS = ['before', 'after'] as const;
export type IconPosition = (typeof ICON_POSITIONS)[number];

export const FLIP_VALUES = ['horizontal', 'vertical'] as const;
export type FlipValue = (typeof FLIP_VALUES)[number];

export const FONT_FAMILY = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
} as const;
export type FontFamily = (typeof FONT_FAMILY)[keyof typeof FONT_FAMILY];

export const FONT_TYPES = ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span', 'div', 'ol', 'ul'] as const;
export type FontType = (typeof FONT_TYPES)[number];

export const FONT_TAGS = ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span', 'li', 'div', 'section', 'ol', 'ul'] as const;
export type FontTag = (typeof FONT_TAGS)[number];

export const FLEX_ITEMS = [
  'end',
  'baseline',
  'inherit',
  'initial',
  'start',
  'center',
  '-moz-initial',
  'revert',
  'unset',
  'stretch',
  'flex-end',
  'flex-start',
  'normal',
  'self-end',
  'self-start',
] as const;
export type FlexItem = (typeof FLEX_ITEMS)[number];

export const FLEX_CONTENT = [
  'end',
  'baseline',
  'inherit',
  'initial',
  'start',
  'center',
  '-moz-initial',
  'revert',
  'unset',
  'space-around',
  'space-between',
  'space-evenly',
  'stretch',
  'flex-end',
  'flex-start',
  'normal',
] as const;
export type FlexContent = (typeof FLEX_CONTENT)[number];

export const FLEX_BASIS = [
  0,
  'auto',
  'inherit',
  'initial',
  'content',
  '-moz-initial',
  'revert',
  'unset',
  'fit-content',
  'max-content',
  'min-content',
  '-moz-max-content',
  '-moz-min-content',
  '-webkit-auto',
] as const;
export type FlexBasis = (typeof FLEX_BASIS)[number];

export const FLEX_DIRECTIONS = [
  'row',
  'inherit',
  'initial',
  '-moz-initial',
  'revert',
  'unset',
  'column',
  'column-reverse',
  'row-reverse',
] as const;
export type FlexDirection = (typeof FLEX_DIRECTIONS)[number];

export const FLEX_WRAPS = [
  'inherit',
  'initial',
  'wrap',
  '-moz-initial',
  'revert',
  'unset',
  'nowrap',
  'wrap-reverse',
] as const;
export type FlexWrap = (typeof FLEX_WRAPS)[number];

export const DISPLAY_TYPES = [
  'ruby',
  'table',
  '-moz-initial',
  'inherit',
  'initial',
  'revert',
  'unset',
  'block',
  'inline',
  'run-in',
  '-ms-flexbox',
  '-ms-grid',
  '-webkit-flex',
  'flex',
  'flow',
  'flow-root',
  'grid',
  'ruby-base',
  'ruby-base-container',
  'ruby-text',
  'ruby-text-container',
  'table-caption',
  'table-cell',
  'table-column',
  'table-column-group',
  'table-footer-group',
  'table-header-group',
  'table-row',
  'table-row-group',
  '-ms-inline-flexbox',
  '-ms-inline-grid',
  '-webkit-inline-flex',
  'inline-block',
  'inline-flex',
  'inline-grid',
  'inline-list-item',
  'inline-table',
  'contents',
  'list-item',
  'none',
] as const;
export type DisplayType = (typeof DISPLAY_TYPES)[number];

export const GRID_FLOW = ['row', 'column', 'row dense', 'column dense'] as const;
export type GridFlow = (typeof GRID_FLOW)[number];

export const POSITIONS = ['relative', 'absolute', 'fixed', 'initial', 'sticky'] as const;
export type Position = (typeof POSITIONS)[number];

export const TEXT_TRANSFORM = ['uppercase', 'lowercase', 'capitalize', 'none'] as const;
export type TextTransform = (typeof TEXT_TRANSFORM)[number];

export const BORDER_RADIUS_VALUES = {
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_RIGHT: 'bottom-right',
  TOP_LEFT: 'top-left',
  TOP_RIGHT: 'top-right',
} as const;
export const BORDER_RADIUS_OPTIONS = Object.values(BORDER_RADIUS_VALUES);
export type BorderRadiusOption = (typeof BORDER_RADIUS_VALUES)[keyof typeof BORDER_RADIUS_VALUES];

export const BORDERS = [
  'default',
  'solid',
  'dashed',
  'dotted',
  'double',
  'groove',
  'ridge',
  'inset',
  'outset',
  'none',
  'hidden',
] as const;
export type Border = (typeof BORDERS)[number];

export const TEXT_ALIGN = ['left', 'center', 'right', 'justify'] as const;
export type TextAlign = (typeof TEXT_ALIGN)[number];

export const TEXT_DECORATION = ['none', 'underline', 'overline', 'line-through'] as const;
export type TextDecoration = (typeof TEXT_DECORATION)[number];

export const BUTTON_COLORS = [
  'primary',
  'secondary',
  'primary-alt',
  'secondary-alt',
  'whisper',
  'whisper-alt',
  'white',
  'black',
] as const;
export type ButtonColor = (typeof BUTTON_COLORS)[number];

export const BUTTON_SIZES = [...SPACERS, 'small', 'medium', 'large', 'icon'] as const;
export type ButtonSize = (typeof BUTTON_SIZES)[number];

export const WHITE_SPACES = ['normal', 'nowrap', 'pre', 'pre-wrap', 'pre-line', 'break-spaces'] as const;
export type WhiteSpace = (typeof WHITE_SPACES)[number];

export const WORD_BREAK = ['normal', 'break-all', 'keep-all', 'break-word'] as const;
export type WordBreak = (typeof WORD_BREAK)[number];

export const NEGATIVE_MARGIN = ['before', 'after'] as const;
export type NegativeMargin = (typeof NEGATIVE_MARGIN)[number];

export const OBJECT_FIT = [
  'contain',
  'cover',
  'fill',
  'none',
  'scale-down',
  'inherit',
  'initial',
  'revert',
  'unset',
] as const;
export type ObjectFit = (typeof OBJECT_FIT)[number];

export const OBJECT_POSITION = [
  'top',
  'bottom',
  'left',
  'right',
  'center',
  'inherit',
  'initial',
  'revert',
  'revert-layer',
  'unset',
] as const;
export type ObjectPosition = (typeof OBJECT_POSITION)[number] | Property.ObjectPosition;
